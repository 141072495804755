const caselist = [{
    img: require('../assets/case/13.png'),
    title: '江西新余科达技工学校',
}, {
    img: require('../assets/case/14.png'),
    title: '南昌职业大学',
}, {
    img: require('../assets/case/15.png'),
    title: '广东茂名幼儿师范专科学校',
}, {
    img: require('../assets/case/16.png'),
    title: '遵义职业技术学院',
}]
const caselist1 = [{
    img: require('../assets/case/17.png'),
    title: '中山博凯幼儿园',
}, {
    img: require('../assets/case/18.png'),
    title: '中山市第四幼儿园',
}, {
    img: require('../assets/case/19.png'),
    title: '深圳半岛苑幼儿园',
}, {
    img: require('../assets/case/20.png'),
    title: '深圳甘坑幼儿园',
}]

const musiclist = [{
    img: require('../assets/case/21.png'),
    title: '广东茂名幼儿师范专科学培训',
}, {
    img: require('../assets/case/22.png'),
    title: '广西英华国际职业学院培训',
}, {
    img: require('../assets/case/23.png'),
    title: '广西幼儿高等师范专科学校培训',
},]
const suolilist = [{
    img: require('../assets/case/24.png'),
    title: '广东茂名幼儿师范专科学培训',
}, {
    img: require('../assets/case/25.png'),
    title: '广西英华国际职业学院培训',
}, {
    img: require('../assets/case/26.png'),
    title: '南昌职业大学培训',
},]
const moneylist = [{
    img: require('../assets/case/27.png'),
    title: '陆丰市第二职业技术学校捐赠仪式',
}, {
    img: require('../assets/case/28.png'),
    title: '陆丰市第二职业技术学校奖学金颁发',
}, {
    img: require('../assets/case/29.png'),
    title: '宜春幼儿师范高等专科学院奖学金颁发',
},{
    img: require('../assets/case/30.png'),
    title: '深圳信息职业技术学院奖学金颁发(2023)',
},{
    img: require('../assets/case/31.png'),
    title: '深圳信息职业技术学院奖学金颁发(2022)',
},{
    img: require('../assets/case/32.png'),
    title: '深圳信息职业技术学院奖学金颁发(2021)',
},]
const hezuolist =[{
    img: require('../assets/case/33.png'),
}, {
    img: require('../assets/case/34.png'),
}, {
    img: require('../assets/case/35.png'),
},{
    img: require('../assets/case/36.png'),
},{
    img: require('../assets/case/37.png'),
},{
    img: require('../assets/case/38.png'),
},{
    img: require('../assets/case/39.png'),
},{
    img: require('../assets/case/40.png'),
},{
    img: require('../assets/case/41.png'),
},{
    img: require('../assets/case/42.png'),
},{
    img: require('../assets/case/43.png'),
},]
const hezuolist1 =[{
    img: require('../assets/case/44.png'),
}, {
    img: require('../assets/case/45.png'),
}, {
    img: require('../assets/case/46.png'),
},{
    img: require('../assets/case/47.png'),
},{
    img: require('../assets/case/48.png'),
},{
    img: require('../assets/case/49.png'),
},{
    img: require('../assets/case/50.png'),
},{
    img: require('../assets/case/51.png'),
},{
    img: require('../assets/case/52.png'),
},{
    img: require('../assets/case/53.png'),
},{
    img: require('../assets/case/54.png'),
},{
    img: require('../assets/case/55.png'),
},]
const rongyulist =[{
    img: require('../assets/case/56.png'),
}, {
    img: require('../assets/case/57.png'),
}, {
    img: require('../assets/case/58.png'),
},{
    img: require('../assets/case/59.png'),
},{
    img: require('../assets/case/60.png'),
},{
    img: require('../assets/case/61.png'),
},{
    img: require('../assets/case/62.png'),
},{
    img: require('../assets/case/63.png'),
},{
    img: require('../assets/case/64.png'),
},{
    img: require('../assets/case/65.png'),
},{
    img: require('../assets/case/66.png'),
},{
    img: require('../assets/case/67.png'),
},]
const rongyulist1 =[{
    img: require('../assets/case/68.png'),
}, {
    img: require('../assets/case/69.png'),
}, {
    img: require('../assets/case/70.png'),
},{
    img: require('../assets/case/71.png'),
},{
    img: require('../assets/case/72.png'),
},{
    img: require('../assets/case/73.png'),
},{
    img: require('../assets/case/74.png'),
},{
    img: require('../assets/case/75.png'),
}]
const casejson = {
    caselist,
    caselist1,
    musiclist,
    suolilist,
    moneylist,
    hezuolist,
    hezuolist1,
    rongyulist,
    rongyulist1,

}
export default casejson;