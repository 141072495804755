<template>
    <div class="case">
        <img class="boximg" src="../assets/case/1.png" alt="" srcset="">
        <div class="podiv" v-show="podivshow">
            <div :class="active==index?'podivone1':'podivone'" v-for="(item,index) in podiv" @click="changepodiv(index)" :key="index">
                <div class="podivimg" v-if="active==index">
                    <img src="../assets/case/icon.png" alt="" srcset="">
                </div>
                <div class="podivtext">{{item.text}}</div>
            </div>
        </div>
        <jobnav name="托幼产业学院" id="podivid" />
        <div class="school">
            <div class="schooltext">2021年标榜半岛与广西幼儿师范高等专科学校成立了大湾区托幼产业学院,以托幼产业发展需求为导向,深化产教融合、校企融合、科教融合,创建互补、互利、互动、多赢的婴幼儿照护人才培养创新平台。</div>
            <div class="schoolimgbox">
                <div class="schoolimg">
                    <img src="../assets/case/2.png" alt="">
                </div>
                <div class="schoolimg">
                    <img src="../assets/case/3.png" alt="">
                </div>
                <div class="schoolimg">
                    <img src="../assets/case/4.png" alt="">
                </div>
            </div>
        </div>
        <jobnav name="学前专业共建" id="podivid" />
        <div class="majorbox">
            <div class="major1">
                <img src="../assets/case/82.png" alt="">
            </div>
            <div class="major">
                <img src="../assets/case/5.png" alt="">
                <div class="majortext">双师交流</div>
            </div>
            <div class="major">
                <img src="../assets/case/6.png" alt="">
                <div class="majortext">共编教材</div>
            </div>
            <div class="major">
                <img src="../assets/case/7.png" alt="">
                <div class="majortext">协同育人</div>
            </div>
        </div>
        <jobnav name="智慧教育实训" id="podivid" />
        <div class="text">2022集团中标了深圳信息职业技术学院“学前教育专业智慧教育技术实训实验基地”项目,目前已经在深圳信息职业技术学院、广西幼儿师范高等专科学校、南昌职业大学、 遵义职业技术学院等高校得到了广泛应用,取得了师生的一致好评。</div>
        <div class="majorbox">
            <div class="major">
                <img src="../assets/case/8.png" alt="">
                <div class="majortext">教师保教活动系统</div>
            </div>
            <div class="major">
                <img src="../assets/case/9.png" alt="">
                <div class="majortext">学生学习活动支持系统</div>
            </div>
            <div class="major">
                <img src="../assets/case/10.png" alt="">
                <div class="majortext">教师培训成长发展系统</div>
            </div>
        </div>
        <div class="majorbox1">
            <div class="major">
                <img src="../assets/case/11.png" alt="">
                <div class="majortext">幼儿园观摩互动系统</div>
            </div>
            <div class="major">
                <img src="../assets/case/12.png" alt="">
                <div class="majortext">幼儿园管理系统</div>
            </div>
        </div>
        <jobnav name="校内外实训基地群" id="podivid" />
        <div class="majorbox1">
            <div class="major" v-for="(item,index) in caselist" :key="index">
                <img :src="item.img" alt="">
                <div class="majortext">{{item.title}}</div>
            </div>
        </div>
        <div class="fenge"></div>
        <div class="majorbox1">
            <div class="major" v-for="(item,index) in caselist1" :key="index">
                <img :src="item.img" alt="">
                <div class="majortext">{{item.title}}</div>
            </div>
        </div>
        <jobnav name="职业技能提升" />
        <div class="navbox">
            <div class="nav">
                奥尔夫音乐培训
            </div>
        </div>
        <div class="majorbox">
            <div class="major" v-for="(item,index) in musiclist" :key="index">
                <img :src="item.img" alt="">
                <div class="majortext">{{item.title}}</div>
            </div>
        </div>
        <div class="navbox">
            <div class="nav">
                蒙台梭利培训
            </div>
        </div>
        <div class="majorbox">
            <div class="major" v-for="(item,index) in suolilist" :key="index">
                <img :src="item.img" alt="">
                <div class="majortext">{{item.title}}</div>
            </div>
        </div>
        <jobnav name="合作院校优秀生奖学基金" id="podivid" />
        <div class="majorbox">
            <div class="major" v-for="(item,index) in moneylist" :key="index">
                <img :src="item.img" alt="">
                <div class="majortext">{{item.title}}</div>
            </div>
        </div>
        <jobnav name="合作伙伴" id="podivid" />
        <div class="navbox">
            <div class="nav">
                校企合作（部分）
            </div>
        </div>
        <div class="hezuobox">
            <div class="hezuo" v-for="(item,index) in hezuolist" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="navbox">
            <div class="nav">
                生态合作（部分）
            </div>
        </div>
        <div class="hezuobox">
            <div class="hezuo" v-for="(item,index) in hezuolist1" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <jobnav name="企业荣誉" id="podivid" />
        <div class="hezuobox">
            <div class="hezuo1" v-for="(item,index) in rongyulist" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="fenge1"></div>
        <div class="hezuobox">
            <div class="hezuo1" v-for="(item,index) in rongyulist1" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="changebox">
            <div class="change1">
                <img src="../assets/case/76.png" alt="">
            </div>
            <div class="change2">
                <img src="../assets/case/77.png" alt="">
            </div>
            <div class="change">
                <img src="../assets/case/78.png" alt="">
            </div>
            <div class="change">
                <img src="../assets/case/79.png" alt="">
            </div>
            <div class="change">
                <img src="../assets/case/80.png" alt="">
            </div>
            <div class="change">
                <img src="../assets/case/81.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import jobnav from "../components/jobnav.vue";
import casejson from "../store/casejson";

export default {
    components: {
        jobnav,
    },
    data() {
        return {
            caselist: [],
            caselist1: [],
            musiclist: [],
            suolilist: [],
            moneylist: [],
            hezuolist: [],
            hezuolist1: [],
            rongyulist: [],
            rongyulist1: [],
            active: 0,
            podivshow: false,
            podiv: [
                {
                    text: "托幼产业学院",
                },
                {
                    text: "学前专业共建",
                },
                {
                    text: "智慧教育实训",
                },
                {
                    text: "校内外实训基地群",
                },
                {
                    text: "合作院校 优秀生奖学基金",
                },
                {
                    text: "合作伙伴",
                },
                {
                    text: "企业荣誉",
                },
            ],
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll, true);
        window.addEventListener("scroll", this.onScroll, true);

        this.caselist = casejson.caselist;
        this.caselist1 = casejson.caselist1;
        this.musiclist = casejson.musiclist;
        this.suolilist = casejson.suolilist;
        this.moneylist = casejson.moneylist;
        this.hezuolist = casejson.hezuolist;
        this.hezuolist1 = casejson.hezuolist1;
        this.rongyulist = casejson.rongyulist;
        this.rongyulist1 = casejson.rongyulist1;
    },
    methods: {
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop;
            console.log(scrollTop);
            if (scrollTop >= 450) {
                this.podivshow = true;
            } else {
                this.podivshow = false;
            }
            let scrollItems = document.querySelectorAll("#podivid");
            for (let i = scrollItems.length - 1; i >= 0; i--) {
                let judge =
                    scrollTop >=
                    scrollItems[i].offsetTop - scrollItems[0].offsetTop ;

                if (judge) {
                    this.active = (i).toString();
                    if(scrollTop<720){
                        this.active = '0'
                    }
                    break;
                }
            }
        },
        changepodiv(index) {
            const element = document.querySelectorAll("#podivid");
            element[index].scrollIntoView({
                behavior: "smooth", 
                block: "start", 
                inline: "nearest", 
            });
        },
    },
};
</script>
<style lang="scss" scoped>
img {
    width: 100%;
    // height: 100%;
}
.case {
    position: relative;
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .podiv {
        background: #fff;
        font-size: 14px;
        position: fixed;
        left: 20px;
        top: 300px;
        border-radius: 20px 0px 0px 20px;
        // padding: 0 20px;
        border: 1px solid #be413f;
        .podivone {
            padding: 20px 20px;
            text-align: center;
            position: relative;
            .podivimg {
                position: absolute;
                width: 11px;
                top: 20px;
                left: 0;
            }
            .podivtext {
                width: 100px;
            }
        }
        .podivone1 {
            padding: 20px 20px;
            text-align: center;
            position: relative;
            background: rgba(190, 65, 63, 0.06);
            .podivimg {
                position: absolute;
                width: 11px;
                top: 20px;
                left: 0;
            }
            .podivtext {
                width: 100px;
            }
        }
    }
    .school {
        width: 70vw;
        margin: 30px auto;
        .schooltext {
            font-size: 18px;
        }
        .schoolimgbox {
            display: grid;
            grid-template-columns: calc(50% - 40px) 25% 25%;
            grid-gap: 20px;
            align-items: center;
            .schoolimg {
                width: 100%;
            }
        }
    }
    .text {
        width: 70vw;
        margin: 30px auto;
        font-size: 18px;
    }
    .majorbox {
        width: 70vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        .major {
            .majortext {
                font-size: 20px;
                text-align: center;
                margin: 10px 0;
            }
        }
        .major1 {
            grid-column: 1 / 4;
        }
    }
    .majorbox1 {
        width: 70vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 20px;
        .major {
            .majortext {
                font-size: 20px;
                text-align: center;
                margin: 10px 0;
            }
        }
    }
    .fenge {
        width: 70vw;
        margin: 30px auto;
        height: 4px;
        background: #ffffff;
        border: 1px solid #eb6619;
    }
    .fenge1 {
        width: 70vw;
        margin: 30px auto;
        height: 1px;
        border-bottom: 2px dashed #ffbd91;
    }
    .navbox {
        width: 70vw;
        margin: 30px auto;
        .nav {
            width: 284px;
            height: 60px;
            background-image: url("../assets/case/icon2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            font-size: 20px;
            font-weight: 500;
            line-height: 60px;
            color: #803808;
            text-align: center;
        }
    }
    .hezuobox {
        width: 70vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 20px;
        .hezuo {
            img {
                width: 80%;
            }
            align-self: center;
            .hezuotext {
                font-size: 20px;
                text-align: center;
                margin: 10px 0;
            }
        }
        .hezuo1 {
            align-self: center;
            .hezuotext {
                font-size: 20px;
                text-align: center;
                margin: 10px 0;
            }
        }
    }
    .changebox {
        width: 70vw;
        margin: 30px auto;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto auto;
        grid-gap: 20px;
        .change1 {
            grid-column: 1 / 3;
        }
        .change2 {
            grid-column: 3 / 5;
        }
    }
}
</style>